import { orderBy } from 'lodash'

import { DATE_RELATED_FIELD_NAMES } from '@/helpers/constants'
import {
  IReport,
  IReportGroup,
  IRetentionTable,
  ITable,
  TSorting,
  ITableDimension,
  IRetentionChartsData,
  IRetentionChartGroupData,
  ICommonTableChunkResponse,
  IRetentionChartItemDataResponse
} from '@/store/typings/report'

export function formatReportData(reportData: ICommonTableChunkResponse[]) {
  return reportData.reduce((formattedReportData: { [key: string]: any }, chunk: ICommonTableChunkResponse) => {
    const chunkProps = Object.keys(chunk)

    return chunkProps.reduce(
      (formattedReportDataWithChunkData: { [key: string]: any }, chunkProp: string) => ({
        ...formattedReportDataWithChunkData,
        [chunkProp]:
          Array.isArray(formattedReportDataWithChunkData[chunkProp]) && Array.isArray(chunk[chunkProp])
            ? [...formattedReportDataWithChunkData[chunkProp], ...chunk[chunkProp]]
            : chunk[chunkProp]
      }),
      formattedReportData
    )
  }, {})
}

export function formatRetentionChartData(chartData: IRetentionChartItemDataResponse[], currentReport: IReport | null): IRetentionChartsData {
  const formattedData: IRetentionChartsData = {
    chart_groups: [],
    charts: []
  }

  const x_value_type =
    currentReport?.retention_tables
      .reduce((allDimensions: ITableDimension[], retentionTable: IRetentionTable) => [...allDimensions, ...retentionTable.dimensions], [])
      .find((dimension: ITableDimension) => dimension.name === currentReport.retention_chart_spots?.[0]?.order_by[0]?.name)?.value_type ?? 'Date'

  chartData.forEach(chartDataItem => {
    const { btn, charts } = chartDataItem

    const chartGroupItem: IRetentionChartGroupData = {
      group_idx: btn.chart_key,
      human_readable_name: btn.chart_key_name,
      name: String(btn.chart_key),
      // value: null,
      value_type: 'Percentage'
    }

    const chartsItem = charts.map(chartItem => ({
      ...chartItem,
      points: chartItem.points.map(point => ({ ...point, y: String(point.rate) })),
      chart_group_id: btn.chart_key,
      x_value_type,
      y_value_type: 'Percentage',
      // predicted_points: [],
      chart_type: 'line'
    }))
    formattedData.chart_groups = [...formattedData.chart_groups, chartGroupItem]
    formattedData.charts = [...formattedData.charts, ...chartsItem]
  })

  return formattedData
}

export function getAllReportsFromReportGroups(reportGroups: IReportGroup[]): IReport[] {
  return reportGroups.reduce((allReports: IReport[], reportGroup: IReportGroup) => [...allReports, ...reportGroup.reports], [])
}

export function defaultCurrentReportTable(tables?: ITable[]): ITable | Partial<ITable> {
  let table: ITable | Partial<ITable> = {}

  if (!tables) return table

  let maxDimensionsTableLength = 0

  tables.forEach(currentTable => {
    const shownDimensionsLength = currentTable.dimensions.filter(dimension => dimension.shown_type === 'Show').length
    if (shownDimensionsLength > maxDimensionsTableLength) {
      maxDimensionsTableLength = shownDimensionsLength
      table = currentTable
    }
  })

  return table
}

export function defaultDateDimension(dimensions: ITableDimension[], selectedDimensions: string[] = []): string | null {
  const sortedSelectedDimensions = orderBy(
    dimensions.filter(dimension => selectedDimensions.includes(dimension.name) && ['Date', 'Week', 'Month'].includes(dimension.value_type)),
    dimension => {
      switch (dimension.value_type) {
        case 'Date':
          return 0
        case 'Week':
          return 1
        case 'Month':
          return 2
        default:
          return 3
      }
    }
  )

  return sortedSelectedDimensions[0]?.name ?? null
}

export function getDefaultDimensionOrder(
  dimensions: ITableDimension[],
  selectedDimensions: string[] = [],
  isRetention = false
): [string, TSorting] | null {
  const sortedSelectedDimensions = orderBy(
    dimensions.filter(dimension => selectedDimensions.includes(dimension.name)),
    dimension => {
      switch (dimension.value_type) {
        case 'Date':
          return 0
        case 'Week':
          return 1
        case 'Month':
          return 2
        default:
          return 3
      }
    }
  )

  if (sortedSelectedDimensions.length === 0) return null

  if (isRetention) {
    return [sortedSelectedDimensions[0].name, 'descending']
  } else {
    const sortedDatesDimensions = sortedSelectedDimensions.filter(dimension => DATE_RELATED_FIELD_NAMES.includes(dimension.value_type))
    return sortedDatesDimensions.length ? [sortedDatesDimensions[0].name, 'ascending'] : [sortedSelectedDimensions[0].name, 'descending']
  }
}

export function getPreparedDimensions(
  dimensions: string[] | undefined,
  extraDimensions: string[],
  table: ITable | IRetentionTable | null
): string[] | null {
  if (!dimensions) return null
  if (dimensions.length === 0 && extraDimensions.length === 0 && table) return [table.dimensions[0]?.name]
  return dimensions
}

export function getPreparedChartGroupsBy(groupsBy: Array<string | undefined> | undefined, extraGroupsBy: string[]): string[] | null {
  if (!groupsBy || (!groupsBy.length && extraGroupsBy.length === 0)) return null
  return groupsBy.filter(groupBy => !!groupBy) as string[]
}
