import axios, { AxiosError, AxiosRequestConfig, CanceledError } from 'axios'
import { VueConstructor } from 'vue'
import * as Sentry from '@sentry/browser'

import { wait } from '@/helpers'
import { storeApp } from '@/store/modules/app'
import { storeAuth } from '@/store/modules/auth'

type IResponse<T> = { id: number; result: T }
type IErrorResponse = { error: { code: number; message: string } }
type TRPCRequest = { jsonrpc: string; method: string; params: object; id: number }

export type APIPlugin = <T = any>(
  methodName: string,
  data?: object | undefined,
  extendAxiosConfig?: AxiosRequestConfig,
  configProps?: {
    checkResourceID?: boolean
    fetchLastResponse?: boolean
    preventErrorShow?: boolean
  }
) => Promise<T>

let batchRequests: Array<TRPCRequest> = []
let batchTimeout: ReturnType<typeof setTimeout> | null = null
const promiseResolvers: { [key: number]: { resolve: (value: unknown) => void; reject: (reason?: unknown) => void } } = {}

export default {
  install(Vue: VueConstructor<Vue>) {
    // help function for waiting for some requests while login or reissue is initiated
    async function waitFor(waitForEvent: 'login' | 'reissue') {
      if (waitForEvent === 'login') {
        return wait(300).then(() => {
          if (!storeApp.api.isLoggedIn) waitFor('login')
        })
      } else if (waitForEvent === 'reissue') {
        return wait(300).then(() => {
          if (storeAuth.isUpdatingToken) waitFor('reissue')
        })
      }
    }

    // will be called before every $api call
    axios.interceptors.request.use(
      async function (config) {
        const methodName: string = config.data.method
        await storeAuth.setLastRequestTimestamp()

        // if token expired or will be expired soon -> reissueToken
        if (storeAuth.shouldUpdateToken && !storeAuth.isUpdatingToken && !['login', 'reissue', 'getAuthUrl', 'getToken'].includes(methodName)) {
          await storeAuth.reissueToken()
        }

        // if user is not logged in (first page load) or token is updating -> wait for end
        if (!['reissue', 'login', 'getAuthUrl', 'getToken'].includes(methodName)) {
          if (!storeApp.api.isLoggedIn) {
            await waitFor('login')
          } else if (storeAuth.isUpdatingToken) {
            await waitFor('reissue')
          }
        }

        // set JWT header for authorized methods
        if (storeAuth.access_token && !['login', 'reissue', 'getAuthUrl', 'getToken', 'getUnauthorizedMethods'].includes(methodName)) {
          config.headers = config.headers || {}
          config.headers['authorization'] = `JWT ${storeAuth.access_token}`
        }
        return config
      },
      function (error) {
        return Promise.reject(error)
      }
    )

    Vue.prototype.$api = async <T = any>(
      methodName: string,
      data?: object,
      extendAxiosConfig: AxiosRequestConfig = {},
      configProps: { checkResourceID?: boolean; fetchLastResponse?: boolean; preventErrorShow?: boolean } = {}
    ) => {
      // Config props
      const { checkResourceID = false, fetchLastResponse = false, preventErrorShow = false } = configProps

      Vue.prototype.$nprogress.start()

      await storeApp.increaseRPCApiCounter()

      // Values to compare before and after loading
      const id = storeApp.rpcApiCounter
      const resourceIDBeforeLoading = storeApp.resourceID

      // eslint-disable-next-line no-undef
      const url = process.env.VUE_APP_API_ADDR || config.VUE_APP_API_ADDR

      const controller = new AbortController()

      const axiosConfig = {
        ...extendAxiosConfig,
        signal: controller.signal
      }

      const rpcRequest: TRPCRequest = {
        jsonrpc: '2.0',
        method: methodName,
        params: data ? data : {},
        id
      }

      // Batch requests for specific method names
      if (['getDynamicSchema', 'getChoices'].includes(methodName)) {
        batchRequests.push(rpcRequest)

        if (!batchTimeout) {
          batchTimeout = setTimeout(async () => {
            const requestsToSend = [...batchRequests]
            batchRequests = []
            batchTimeout = null

            try {
              const response = await axios.post<IResponse<T>[]>(url, requestsToSend, axiosConfig)
              Vue.prototype.$nprogress.done()

              response.data.forEach(res => {
                if (res.id && res.hasOwnProperty('result')) {
                  promiseResolvers[res.id].resolve(res.result)
                } else {
                  promiseResolvers[res.id].reject(new Error('Batch request failed'))
                }

                delete promiseResolvers[res.id]
              })
            } catch (e) {
              Vue.prototype.$nprogress.done()

              requestsToSend.forEach(req => {
                promiseResolvers[req.id].reject(e)
                delete promiseResolvers[req.id]
              })

              console.error(e)
            }
          }, 300)
        }

        return new Promise((resolve, reject) => {
          promiseResolvers[id] = { resolve, reject }
        })
      }

      if (fetchLastResponse) {
        storeApp.setMaxRequestsIDs({ method: methodName, id })
      }

      storeApp.setRequestController({ methodName, controller, requestID: id })

      try {
        const response = await axios.post<IResponse<T>>(url, rpcRequest, axiosConfig)

        // Check resource id after response loading
        const resourceIDAfterLoading: string = storeApp.resourceID
        const resourceIdWasChange: boolean = checkResourceID && resourceIDAfterLoading !== resourceIDBeforeLoading

        // Skip response if request is not last with the same method name
        const maxRequestID: number = storeApp.api.maxRequestsIDs[methodName] ?? id
        const skipInactiveRequest: boolean = fetchLastResponse && maxRequestID !== id

        storeApp.removeRequestController({ methodName, requestID: id })

        if (response.data?.id === id && response.data.hasOwnProperty('result') && !resourceIdWasChange && !skipInactiveRequest) {
          Vue.prototype.$nprogress.done()

          return Promise.resolve(response.data.result)
        } else if (resourceIdWasChange) {
          throw 'Resource was change'
        } else if (skipInactiveRequest) {
          throw 'Skip inactive request'
        }
      } catch (e) {
        const error = e as AxiosError<IErrorResponse> | CanceledError<IErrorResponse>
        Vue.prototype.$nprogress.done()

        if (typeof error === 'string') throw error
        if (error instanceof CanceledError) throw `${methodName} request was canceled`

        console.error(error)

        const { message, code } = error.response!.data.error!

        if (!preventErrorShow) {
          // 32503 - MAINTENANCE MODE ERROR.
          // -32601 - METHOD NOT FOUND.
          // 32403 - USER UNAUTHORIZED.
          if (code === 32503 && !storeApp.api.maintenanceModeMessages.includes(message)) {
            storeApp.setMaintenanceMode({ maintenanceMode: message, action: 'push' })
            Vue.prototype.$sauronMessage({
              message,
              showClose: true,
              duration: 0,
              type: 'warning',
              onClose: () => {
                storeApp.setMaintenanceMode({ maintenanceMode: message, action: 'delete' })
              }
            })
          } else if (![32503, 32403, -32601].includes(code)) {
            Vue.prototype.$sauronNotify.show({
              type: 'error',
              name: message,
              message
            })
          }
        }

        Sentry.withScope(function (scope) {
          scope.setTag('method-name', methodName)
          // group errors together based on their request and response
          scope.setFingerprint([methodName, url, message])
          error.name = `${error.name} | method: ${methodName}`
          Sentry.captureException(error)
        })

        storeApp.removeRequestController({ methodName, requestID: id })
        return Promise.reject(error.response!.data.error)
      }
    }
  }
}
