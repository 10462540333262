import Vue from 'vue'
import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import { Route } from 'vue-router'

import store from '@/store'
import router from '@/router/index'
import { storeAuth } from '@/store/modules/auth'
import { storeBlog } from '@/store/modules/blog'
import { storeReport } from '@/store/modules/report'
import { storeInventory } from '@/store/modules/inventory'
import { storeSnapshots } from '@/store/modules/snapshots'
import { storeDashboard } from '@/store/modules/dashboard'
import { storeConfigurations } from '@/store/modules/configurations'
import { storeNotificationCenter } from '@/store/modules/notificationCenter'
import { storeFunnels } from '@/store/modules/funnels'
import { storeAnalyticsStorageData } from '@/store/modules/analyticsStorageData'
import { MAIN_DASHBOARD_CUSTOM_UUID, RESOURCE_TYPES } from '@/helpers/constants'
import { removeReactivity, wait } from '@/helpers'
import { ISelectedFilter } from '@/store/typings/filter'
import { getStorageByPath } from '@/helpers/storage'
import {
  IAppState,
  IAppApiState,
  ICurrentEntity,
  IAppSocketState,
  IMessageCollector,
  IChangeResourcePayload,
  IUpdateEntityDataPayload
} from '@/store/typings/app'
import { IAnalyticsData } from '@/store/typings/analyticsStorageData'

@Module({ dynamic: true, store, name: 'app' })
class AppModule extends VuexModule implements IAppState {
  api: IAppApiState = {
    isLoggedIn: false,
    maintenanceModeMessages: [],
    maxRequestsIDs: {},
    userId: null,
    requestsCancels: {}
  }
  socket: IAppSocketState = {
    isLoggedIn: false,
    isConnected: false,
    reconnectError: false
  }
  initialDataStatus = 'pending'
  rpcCounter = 0
  rpcApiCounter = 0
  messageCollector: IMessageCollector = {}
  rootGettersByPageNameMap: { [key: string]: string } = {
    reportsPage: 'report/currentReport',
    inventoryPage: 'inventory/currentInventory',
    dashboardPage: 'dashboard/currentDashboard',
    configurationsPage: 'configurations/currentConfiguration',
    addEditConfigurationPage: 'configurations/currentConfiguration',
    grabbersPage: 'configurations/currentConfiguration',
    contentServerPage: 'configurations/currentConfiguration',
    grabberDetailsPage: 'configurations/currentConfiguration',
    addEditGrabbersPage: 'configurations/currentConfiguration'
  }
  customPredefinedFilter: ISelectedFilter[] = getStorageByPath<ISelectedFilter[]>('app.customPredefinedFilter', [])
  _resourceUpdatedTime: string | null = null

  get currentRouter(): Route {
    return this.context.rootState.route
  }
  get isLoggedInSocket(): boolean {
    return this.socket.isLoggedIn
  }

  get currentRouteName(): string {
    return this.currentRouter?.name ?? ''
  }

  get activeModuleStoreName(): string {
    return this.rootGettersByPageNameMap[this.currentRouteName]
  }

  get parentRouteName(): string {
    return router.currentRoute.matched.find(currentRouteMatched => currentRouteMatched.name === this.currentRouteName)?.parent?.name ?? ''
  }

  get currentPageName(): string {
    return this.context.getters[this.activeModuleStoreName]?.name ?? ''
  }

  get resourceID(): string {
    return this.currentRouteName === 'mainDashboardPage' ? MAIN_DASHBOARD_CUSTOM_UUID : this.currentRouter?.params?.id ?? ''
  }

  get currentPageType(): TPageType {
    if (this.currentRouteName === 'reportsPage') return 'report'
    if (this.currentRouteName === 'inventoryPage') return 'inventory'
    if (this.currentRouteName === 'dashboardPage') return 'dashboard'
    if (this.currentRouteName === 'funnelsPage') return 'filter-filled'
    if (storeConfigurations.grabbersList.some(grabbersListItem => grabbersListItem.id === this.resourceID)) return 'grabber'
    if (this.currentRouteName === 'articlesPage' || this.currentRouteName === 'articlePage') return 'blog'
    if (storeConfigurations.remoteConfigurationsList.some(remoteConfiguration => remoteConfiguration.id === this.resourceID)) return 'config'
    if (storeConfigurations.contentServerList.some(contentServer => contentServer.id === this.resourceID)) return 'content-server'
    if (storeConfigurations.uaChronologyList.some(uaChronology => uaChronology.id === this.resourceID)) return 'ua-config'
    return 'main'
  }

  get pageTitle(): string {
    switch (this.currentPageType) {
      case 'content-server':
        return 'Content Server'
      case 'ua-config':
        return 'UA configurations'
      case 'grabber':
        return 'Integration'
      default:
        return this.currentRouter.meta?.title ?? this.currentRouteName
    }
  }

  get resourceUpdatedTime(): string | null {
    return this._resourceUpdatedTime
  }

  get predefinedConfigurationsData(): IAnalyticsData {
    const hasSelectedSnapshot = Object.entries(storeSnapshots.activeSnapshotData).length
    const selectedSnapshotIsNotActual = storeSnapshots.activeSnapshotDataIsNotActual

    if (selectedSnapshotIsNotActual && hasSelectedSnapshot) {
      return { ...storeSnapshots.activeSnapshotData, ...storeSnapshots.updatedActiveSnapshotData }
    } else if (hasSelectedSnapshot) {
      return storeSnapshots.activeSnapshotData
    }
    return storeAnalyticsStorageData.analyticsAllData
  }

  get currentEntity(): ICurrentEntity {
    switch (this.currentRouteName) {
      case 'reportsPage':
        return storeReport.currentReport
      case 'inventoryPage':
        return storeInventory.currentInventory
      case 'dashboardPage':
        return storeDashboard.currentDashboard
      case 'funnelsPage':
        return storeFunnels.currentFunnelsReport
      case 'configurationsPage':
      case 'grabbersPage':
      case 'grabberDetailsPage':
      case 'contentServerPage':
      case 'addEditConfigurationPage':
      case 'addEditGrabbersPage':
      case 'uaChronologyPage':
        return storeConfigurations.currentConfiguration
    }
  }

  @MutationAction
  async increaseRPCApiCounter() {
    await wait(0)
    return {
      rpcApiCounter: this.rpcApiCounter + 1
    }
  }

  @Mutation
  increaseRPCCounter() {
    this.rpcCounter++
  }

  @Mutation
  setSocketLoggedIn(isLoggedIn: boolean) {
    this.socket.isLoggedIn = isLoggedIn
  }

  @Mutation
  setApiLoggedIn(isLoggedIn: boolean) {
    this.api.isLoggedIn = isLoggedIn
  }

  @Mutation
  setUserId(userId: string | null) {
    this.api.userId = userId
    Vue.prototype?.$gtag?.config({ user_id: userId }) // locally disabled that's why ?. used
  }

  @Mutation
  setInitialDataStatus(status: string) {
    this.initialDataStatus = status
  }

  @Mutation
  setResourceUpdatedTime(resourceUpdatedTime: string | null) {
    this._resourceUpdatedTime = resourceUpdatedTime
  }

  @Mutation
  setMaintenanceMode({ maintenanceMode, action }: { maintenanceMode: string; action: string }) {
    if (action === 'push') {
      this.api.maintenanceModeMessages.push(maintenanceMode)
    } else if (action === 'delete') {
      this.api.maintenanceModeMessages = this.api.maintenanceModeMessages.filter(
        (maintenanceModeMessage: string) => maintenanceModeMessage !== maintenanceMode
      )
    }
  }

  @Mutation
  setCustomPredefinedFilter(customPredefinedFilter: ISelectedFilter[]) {
    this.customPredefinedFilter = customPredefinedFilter
  }

  @Mutation
  setMaxRequestsIDs({ method, id }: { method: string; id: number }) {
    this.api = {
      ...this.api,
      maxRequestsIDs: {
        ...this.api.maxRequestsIDs,
        [method]: id
      }
    }
  }

  @Mutation
  setRequestController({ methodName, requestID, controller }: { methodName: string; requestID: number; controller: AbortController }) {
    const prevActiveRequests = this.api.requestsCancels[methodName]?.activeRequests ?? {}
    this.api.requestsCancels[methodName] = {
      activeRequests: {
        ...prevActiveRequests,
        [requestID]: controller
      },
      lastRequestID: requestID
    }
  }

  @Mutation
  removeRequestController({ methodName, requestID }: { methodName: string; requestID: number }) {
    if (this.api.requestsCancels[methodName]?.lastRequestID === requestID) {
      this.api.requestsCancels[methodName].lastRequestID = null
    }

    if (this.api.requestsCancels[methodName]?.activeRequests?.[requestID]) {
      delete this.api.requestsCancels[methodName].activeRequests[requestID]
    }
  }

  @Mutation
  initMessageCollector(payload: { method: string; uuid: string }) {
    // Init message collector with method name
    this.messageCollector = {
      ...this.messageCollector,
      [payload.uuid]: { value: [], counter: 0, method: payload.method, resourceID: this.resourceID }
    }
  }

  @Mutation
  removeMessageFromCollector(id: string) {
    const clonedMessageCollector = removeReactivity(this.messageCollector)
    delete clonedMessageCollector[id]

    this.messageCollector = clonedMessageCollector
  }

  @Mutation
  addMessageToCollector(message: IWSMessageParams) {
    const messageCollectorData = this.messageCollector[message.uuid]

    // Ship ping messages
    if (!messageCollectorData) return

    messageCollectorData.counter++
    Array.isArray(message.result) ? messageCollectorData.value.push(...message.result) : messageCollectorData.value.push(message.result)

    this.messageCollector = { ...this.messageCollector, [message.uuid]: messageCollectorData }
  }

  @Mutation
  removeMessagesFromCollector(methods: string[]) {
    const clonedMessageCollector = removeReactivity(this.messageCollector)
    const messageUUIDs = Object.keys(clonedMessageCollector)

    for (const messageUUID of messageUUIDs) {
      if (methods.includes(clonedMessageCollector[messageUUID].method)) {
        delete clonedMessageCollector[messageUUID]
      }
    }

    this.messageCollector = clonedMessageCollector
  }

  @Mutation
  WS_ONOPEN() {
    if (storeAuth.access_token) {
      storeAuth.loginWS()
    }

    this.socket = { ...this.socket, isConnected: true }
  }

  @Action
  async getInitialData() {
    let promiseAll = null

    if (storeAuth.shouldUpdateToken) {
      await storeAuth.reissueToken()
    }

    try {
      promiseAll = await Promise.all([
        storeReport.loadReportGroups(),
        storeConfigurations.loadConfigurationsGroups(),
        storeInventory.loadInventoryTypes(),
        storeDashboard.loadDashboards(),
        // storeNotificationCenter.loadNotifications(),
        storeAnalyticsStorageData.loadGlobalAnalyticsStorageData(),
        // storeBlog.loadSections(),
        storeFunnels.loadFunnelReports()
      ])

      this.setInitialDataStatus('success')
    } catch (e) {
      this.setInitialDataStatus('error')
    }

    return promiseAll
  }

  @Action
  async isInitialDataLoaded() {
    // check is initial data loaded. Give 10 secs for check
    const statusCheckerPromise = new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (this.initialDataStatus === 'success') {
          resolve(this.initialDataStatus)
          clearInterval(interval)
        } else if (this.initialDataStatus === 'error') {
          reject(this.initialDataStatus)
          clearInterval(interval)
        }
      }, 500)
    })
    const timeoutPromise = new Promise(reject => setTimeout(() => reject(this.initialDataStatus), 10000))

    return await Promise.race([statusCheckerPromise, timeoutPromise])
  }

  @Action
  async loadResourceUpdatedTime(resource_type: string) {
    const res = await Vue.prototype.$api('getResourceLastUpdate', {
      resource_id: this.resourceID,
      resource_type
    })
    const resourceUpdatedTime: string | null = res?.updated_at ?? null

    this.setResourceUpdatedTime(resourceUpdatedTime)
  }

  @Action
  async loadResourceChanges(resource_type: string) {
    const previews_version_id = storeAnalyticsStorageData.analyticsAllData?.version_id
    const current_version_id = this.currentEntity && this.currentEntity!.version_id

    if (!previews_version_id || current_version_id === previews_version_id) return null

    const resourceChanges = await Vue.prototype.$api('getResourceChanges', {
      resource_id: this.resourceID,
      version_id: previews_version_id,
      resource_type
    })

    if (resource_type === RESOURCE_TYPES.reportsPage) {
      storeReport.updateReportResource({ resourceChanges })
    } else if (resource_type === RESOURCE_TYPES.dashboardPage) {
      storeDashboard.updateDashboardResource({ resourceChanges })
    }
  }

  @Action
  updateEntity(payload: IUpdateEntityDataPayload) {
    if (payload.type === RESOURCE_TYPES.dashboardPage) {
      storeDashboard.updateDashboardCharts(payload.id)
    } else if (payload.type === RESOURCE_TYPES.reportsPage) {
      storeReport.updateReport(payload.id)
    } else if (payload.type === RESOURCE_TYPES.inventoryPage) {
      storeInventory.updateCards(payload.id)
    }
    this.setResourceUpdatedTime(payload.updated_at)
  }

  @Action
  reloadEntity(payload: IChangeResourcePayload) {
    if (payload.type === RESOURCE_TYPES.dashboardPage) {
      storeDashboard.reloadDashboards({ updatedDashboardUUID: payload.id, resourceChanges: payload.changes })
    } else if (payload.type === RESOURCE_TYPES.reportsPage) {
      storeReport.reloadReportGroups({ updatedReportUUID: payload.id, resourceChanges: payload.changes })
    } else if (payload.type === RESOURCE_TYPES.inventoryPage) {
      storeInventory.reloadInventoryTypes(payload.id)
    }
  }

  @Action
  cancelLastRequest(methodName: string) {
    if (this.api.requestsCancels[methodName] && this.api.requestsCancels[methodName].lastRequestID) {
      const lastRequestID = this.api.requestsCancels[methodName].lastRequestID!
      this.api.requestsCancels[methodName].activeRequests[lastRequestID].abort()
    }
  }
}

export const storeApp: InstanceType<typeof AppModule> = getModule(AppModule)
